<template>
  <container ref="container" @error="init">
    <!-- 过滤器 -->
    <publicFilter
      class="publicFilter"
      :filter="filter.list"
      :tag="tag.list"
      @confirm="confirm"
    />

    <!-- 容器 -->
    <div class="body">
      <!-- 排序 -->
      <div class="order">
        <div
          class="order-item"
          :class="{ 'order-item_active': order.index == index }"
          v-for="(item, index) in order.list"
          @click="orderChange(index)"
          :key="item.value"
        >
          <div class="order-item-title">{{ item.title }}</div>
          <iconfont
            v-if="item.value == 'desc'"
            class="order-item-icon"
            name="arrow-down"
          />
          <iconfont
            v-else-if="item.value == 'asc'"
            class="order-item-icon"
            name="arrow-up"
          />
        </div>
      </div>

      <!-- 列表 -->
      <container ref="caregiver" class="caregiver" @error="getData">
        <div class="caregiver-list" v-if="caregiver.list.length">
          <caregiver-card
            class="caregiver-card"
            :detail="item"
            v-for="item in caregiver.list"
            :key="item.id"
          />
        </div>
        <!-- 更多 -->
        <template v-slot:canload>
          <div @click="more" class="caregiver-more">查看更多</div>
        </template>
        <!-- 加载 -->
        <template v-slot:loading>
          <div class="caregiver-footer">加载中</div>
        </template>
        <!-- 完成 -->
        <template v-slot:complete>
          <div class="caregiver-footer">没有更多了</div>
        </template>
      </container>
    </div>
  </container>
</template>

<script>
import publicFilter from "@/components/publicFilter.vue";
import caregiverCard from "@/components/caregiver-card.vue";

export default {
  name: "postpartumList",
  components: {
    publicFilter,
    caregiverCard,
  },
  data() {
    return {
      filter: {
        value: {
          age: "",
          lv: "",
        },
        list: {},
      },

      tag: {
        value: "",
        list: [],
      },

      order: {
        index: 0,
        list: [
          {
            title: "热度",
            name: "hot",
            value: "desc",
          },
          {
            title: "好评",
            name: "praise",
            value: "desc",
          },
          {
            title: "按年龄",
            name: "age",
            value: "desc",
          },
        ],
      },

      caregiver: {
        list: [],
        page: 1,
      },
    };
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      try {
        // 设置状态
        this.$refs.container.status("init", {
          height: "100vh",
        });

        // 获取数据
        const [age, lv, tag] = await Promise.all([
          this.$axios("/api/mainstation/selectYsage"),
          this.$axios("/api/mainstation/selectYslevel"),
          this.$axios("/api/mainstation/selectYsflag"),
        ]);

        // 设置数据
        this.filter.list = {
          age: {
            title: "产康师年龄",
            list: age.data,
          },
          lv: {
            title: "服务级别",
            list: lv.data,
          },
        };
        this.tag.list = tag.data;

        // 设置状态
        this.$refs.container.status("default");

        // 清空数据
        this.caregiver.page = 1;
        this.caregiver.list = [];

        // 获取数据
        this.getData();
      } catch (e) {
        // 设置状态
        this.$refs.container.status("error", {
          text: e.message,
          height: "100vh",
        });
      }
    },

    /**
     * 确认
     */
    async confirm(e) {
      // 设置页面筛选
      this.filter.value.age = e.filter.age;
      this.filter.value.lv = e.filter.lv;
      this.tag.value = e.tag;

      // 清空数据
      this.caregiver.page = 1;
      this.caregiver.list = [];

      // 获取数据
      this.getData();
    },

    /**
     * 获取数据
     */
    async getData() {
      try {
        // 获取排序
        const getOrder = () => {
          // 获取当前排序
          const current = this.order.list[this.order.index];

          // 获取参数
          const result = {
            hot: {
              hotud: current.value,
            },
            praise: {
              goodevlud: current.value,
            },
            age: {
              ageud: current.value,
            },
          };

          return result[current.name];
        };

        // 设置状态
        if (this.caregiver.page == 1) {
          this.$refs.caregiver.status("init", {
            height: "50vh",
          });
        } else {
          this.$refs.caregiver.status("loading");
        }

        // 获取数据
        const result = await this.$axios("/api/mainstation/selectYuesao", {
          params: {
            type: 3,
            page: this.caregiver.page,
            agearea: this.filter.value.age,
            level: this.filter.value.lv,
            flag: this.tag.value,
            ...getOrder(),
          },
        });

        // 设置数据
        this.caregiver.list = this.caregiver.list.concat(result.data);

        // 设置状态
        this.$refs.caregiver.status(
          this.caregiver.list.length ? "canload" : "complete"
        );
      } catch (e) {
        // 设置状态
        this.$refs.caregiver.status("error", {
          test: e.message,
        });
      }
    },

    /**
     * 更多
     */
    more() {
      // 设置页号
      this.caregiver.page = this.caregiver.page + 1;

      // 获取数据
      this.getData();
    },

    // /**
    //  * 标签更改
    //  */
    // tagChange(index) {
    //   this.tag.list[index].selected = !this.tag.list[index].selected;

    //   // 当非排除项被选中是，取消所有排除项
    //   if (this.tag.list[index].exclude) {
    //     this.tag.list.forEach((item) => {
    //       if (!item.exclude) {
    //         item.selected = false;
    //       }
    //     });
    //   } else {
    //     this.tag.list.forEach((item) => {
    //       if (item.exclude) {
    //         item.selected = false;
    //       }
    //     });
    //   }

    //   // 当没有任何一个标签被选中时，选中兜底项
    //   if (this.tag.list.every((item) => item.selected == false)) {
    //     this.tag.list.find((item) => item.base).selected = true;
    //   }
    // },

    /**
     * 排序修改
     */
    orderChange(index) {
      if (this.order.index == index) {
        if (this.order.list[index].value == "desc")
          this.order.list[index].value = "asc";
        else if (this.order.list[index].value == "asc")
          this.order.list[index].value = "desc";
      }
      this.order.index = index;

      // 清空数据
      this.caregiver.list = [];
      this.caregiver.page = 1;

      // 获取数据
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  // 过滤器
  .publicFilter {
    margin-top: 50px;
  }

  // 主体
  .body {
    width: 1200px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 70px;
  }

  // 排序
  .order {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: stretch;
    padding: 0 20px;

    // 排序-元素
    &-item {
      margin-left: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e6e6e6;
      border-bottom: none;
      background-color: #f2f2f2;
      color: #ed207b;
      font-size: 16px;
      height: 42px;
      width: 100px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      cursor: pointer;

      &_active {
        color: #fff;
        background-color: #ed207b;
      }

      &-icon {
        margin-left: 10px;
      }
    }
  }

  // 月嫂
  .caregiver {
    border-radius: 10px;
    background-color: #f2f2f2;
    border: 1px solid #eaeaea;

    // 列表
    &-list {
      display: flex;
      flex-wrap: wrap;
      padding: 15px;
      // padding-bottom: 130px;
    }

    &-card {
      margin: 5px;
    }

    // 更多
    &-more {
      cursor: pointer;
      font-size: 18px;
      height: 44px;
      width: 130px;
      border-radius: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px auto;
      background-color: #ed207b;
      color: #fff;
    }

    // 底部
    &-footer {
      font-size: 14px;
      color: #a7a7a7;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px 0;
    }
  }
}
</style>


<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  // 过滤器
  .publicFilter {
    margin-top: rpx(40);
  }

  // 主体
  .body {
    margin: auto;
    margin-top: rpx(40);
    margin-bottom: rpx(40);
  }

  // 排序
  .order {
    display: flex;
    align-items: center;
    width: stretch;
    padding: 0 rpx(30);

    // 排序-元素
    &-item {
      margin-right: rpx(40);
      display: flex;
      align-items: center;
      justify-content: center;
      border: rpx(2) solid #e6e6e6;
      border-bottom: none;
      background-color: #f2f2f2;
      color: #ed207b;
      font-size: rpx(24);
      height: rpx(54);
      width: rpx(140);
      border-top-left-radius: rpx(10);
      border-top-right-radius: rpx(10);
      cursor: pointer;

      &_active {
        color: #fff;
        background-color: #ed207b;
      }

      &-icon {
        margin-left: rpx(10);
      }
    }
  }

  // 月嫂
  .caregiver {
    border-radius: rpx(20);

    // 列表
    &-list {
      display: flex;
      flex-wrap: wrap;
      // padding: 20px;
      padding: rpx(15);
      // padding-bottom: 130px;
    }

    &-card {
      margin: rpx(15);
    }

    // 更多
    &-more {
      cursor: pointer;
      font-size: rpx(28);
      height: rpx(70);
      width: rpx(170);
      border-radius: rpx(35);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: rpx(40) auto;
      background-color: #ed207b;
      color: #fff;
    }

    // 底部
    &-footer {
      font-size: rpx(28);
      color: #a7a7a7;
      height: rpx(70);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: rpx(40) 0;
    }
  }
}
</style>

